[data-marqy] {
  position: relative;
  overflow: hidden;
}

[data-marqy-inner] {
  display: flex;
}

[data-marqy][data-direction='right'] [data-marqy-inner] {
  justify-content: flex-end;
}

[data-marqy-content] {
  display: flex;
  flex: 1 0 auto;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  will-change: transform;
}

[data-marqy][data-direction='left'] [data-marqy-content] {
  animation-name: marqyL;
}

[data-marqy][data-direction='right'] [data-marqy-content] {
  animation-name: marqyR;
}

[data-marqy][data-pause-on-hover]:hover [data-marqy-content] {
  animation-play-state: paused;
}

[data-marqy-item] {
  flex-grow: 0;
}

@keyframes marqyL {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes marqyR {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@media (prefers-reduced-motion) {
  [data-marqy-inner] {
    overflow-x: scroll;
  }

  [data-marqy-content] {
    animation: none !important;
  }
}
