/* Scroll reveal animations */

[data-section] {
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-out;

  &.is-hidden {
    opacity: 0.001;
  }
}

[data-section='heroBasic'] {
  h2,
  p,
  a {
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: theme('transitionTimingFunction.out-quint');
  }

  h2 {
    transition-delay: 0.2s;
  }

  p {
    transition-delay: 0.3s;
  }

  a {
    transition-delay: 0.4s;
  }

  &.is-hidden {
    h2,
    p,
    a {
      transform: translateY(2rem);
      opacity: 0.001;
    }
  }
}

[data-section='heroProduct'] {
  .product-title,
  h2,
  p,
  a,
  .media {
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: theme('transitionTimingFunction.out-quint');
  }

  .product-title {
    transition-delay: 0.2s;
  }

  h2 {
    transition-delay: 0.3s;
  }

  p {
    transition-delay: 0.4s;
  }

  a {
    transition-delay: 0.5s;
  }

  .media {
    transition-delay: 0.6s;
  }

  &.is-hidden {
    .product-title,
    h2,
    p,
    a,
    .media {
      transform: translateY(2rem);
      opacity: 0.001;
    }
  }
}

[data-section='heroTwoUp'] {
  h2,
  .text,
  a,
  .media {
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: theme('transitionTimingFunction.out-quint');
  }

  h2 {
    transition-delay: 0.2s;
  }

  .text {
    transition-delay: 0.3s;
  }

  a {
    transition-delay: 0.4s;
  }

  .media {
    transition-delay: 0.5s;
  }

  &.is-hidden {
    h2,
    .text,
    a,
    .media {
      transform: translateY(2rem);
      opacity: 0.001;
    }
  }
}

[data-section='heroNewsletter'] {
  h2,
  .text-body,
  .form,
  .media {
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: theme('transitionTimingFunction.out-quint');
  }

  h2 {
    transition-delay: 0.2s;
  }

  .text-body {
    transition-delay: 0.3s;
  }

  .form {
    transition-delay: 0.4s;
  }

  .media {
    transition-delay: 0.5s;
  }

  &.is-hidden {
    h2,
    .text-body,
    .form,
    .media {
      transform: translateY(2rem);
      opacity: 0.001;
    }
  }
}

[data-section='twoUp'] {
  .two-up-content,
  .two-up-media,
  .two-up-detail {
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: theme('transitionTimingFunction.out-quint');
  }

  .two-up-media {
    transition-delay: 0.2s;
  }

  .two-up-content {
    transition-delay: 0.3s;
  }

  &.is-hidden {
    .two-up-content,
    .two-up-media,
    .two-up-detail {
      transform: translateY(2rem);
      opacity: 0.001;
    }
  }
}

[data-section='threeUp'] {
  h2,
  .three-up-detail,
  .media {
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: theme('transitionTimingFunction.out-quint');
  }

  h2 {
    transition-delay: 0.2s;
  }

  &.is-hidden {
    h2,
    .three-up-detail,
    .media {
      transform: translateY(2rem);
      opacity: 0.001;
    }
  }
}

[data-section='stats'] {
  h2,
  .stat {
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: theme('transitionTimingFunction.out-quint');
  }

  h2 {
    transition-delay: 0.2s;
  }

  &.is-hidden {
    h2,
    .stat {
      transform: translateY(2rem);
      opacity: 0.001;
    }
  }
}
