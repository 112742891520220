/* Shared styles for list boxes and combo boxes */
.trigger {
  @apply h-50 w-full appearance-none rounded-10 border-1 border-grayscale-04 bg-cloud pl-20 pr-16 text-16 font-[440] leading-150 -tracking-1 transition duration-200 hover:border-grayscale-05 focus-visible:border-cipher focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-cipher disabled:cursor-not-allowed disabled:opacity-50 m:text-14 flex items-center justify-between data-[state=open]:ring-cipher data-[state=open]:border-cipher data-[state=open]:outline-none data-[state=open]:ring-1;
}

.options-container {
  @apply border !max-h-190 overflow-y-auto overflow-x-hidden rounded-10 border-grayscale-04 bg-white p-4 text-charcoal shadow-md outline-none no-scrollbar empty:invisible data-[closed]:scale-95 data-[closed]:opacity-0 transition duration-100 ease-out origin-top;
}

.options-item {
  @apply flex items-center py-5 data-[focus]:bg-grayscale-02/60 rounded-10 pl-4;
}

.options-item-checked {
  @apply ml-2 h-12 w-12 mr-6 invisible group-data-[selected]:visible;
}

.icon-indicator {
  @apply w-12 h-full right-12 top-0 absolute text-charcoal;
}
