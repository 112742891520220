@import url('./navigation.css');
@import url('./marqy.css');
@import url('./search.css');
@import url('./scroll.css');
@import url('./components.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-kerning: normal;
    font-variation-settings: 'slnt' 0;
    font-feature-settings: 'kern' 1;
  }

  html {
    scroll-padding-top: 12rem;
    font-size: 62.5%;

    --header-height: 64px;

    @screen l {
      --header-height: 100px;
    }
  }

  body {
    @apply text-16;
  }

  ::selection {
    @apply bg-cipher-01 text-charcoal;
  }
}

@layer utilities {
  .DEBUG {
    @apply border-1 border-[red] bg-[red]/10;
  }

  .bleed {
    @apply relative inset-x-1/2 mx-[-50vw] w-screen;
  }

  .no-bleed {
    @apply static left-auto right-auto mx-[unset] w-[unset];
  }

  .h-screen-s {
    height: 100vh;
    height: 100svh;
  }

  .h-screen-l {
    height: 100vh;
    height: 100lvh;
  }

  .text-balance {
    text-wrap: balance;
  }

  .grid-main {
    @apply grid grid-cols-12 gap-x-20;
  }

  .p-main {
    @apply px-15 m:px-25;
  }

  .slant {
    font-variation-settings: 'slnt' 30;
  }

  .text-body {
    @apply text-14 font-light leading-140 text-grayscale-06 m:text-16 m:leading-154;
  }

  .text-legal {
    @apply text-10 font-light leading-150 m:text-12 [&_a]:underline [&_a]:decoration-from-font [&_a]:underline-offset-1 [&_a]:transition;
  }

  .rich-text {
    * + p,
    * + ul,
    * + ol {
      @apply mt-20;
    }

    li ul li,
    li ol li {
      @apply mt-4 m:mt-8;
    }

    li + li {
      @apply mt-4 m:mt-8;
    }

    * + h2,
    * + h3,
    * + h4 {
      @apply mt-50;
    }

    .hyperlink {
      @apply underline decoration-1 underline-offset-2 transition-colors hover:text-cipher;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
