.ais-SearchBox {
  @apply relative w-full;

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &-form {
    @apply flex w-full items-center;
  }

  &-input {
    @apply grow appearance-none rounded-10 border-1 border-grayscale-02 bg-cloud px-13 py-11 text-13 font-[440] outline-none transition duration-200 hover:border-grayscale-03 focus:border-cipher m:text-14;
  }

  &-submit {
    @apply absolute right-13 z-1 block;
  }
}

.ais-InfiniteHits {
  .ais-Highlight-highlighted {
    @apply bg-cipher-01;
  }
}
