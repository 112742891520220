.NavigationMenuRoot {
  @apply relative hidden w-screen justify-center l:flex;

  > div:not(.ViewportPosition) {
    @apply relative z-10;
  }
}

.NavigationMenuList {
  @apply z-10 flex h-40 items-center rounded-10 backdrop-blur-[10px];
}

.NavigationMenuLink,
.NavigationMenuTrigger {
  @apply relative flex h-full select-none items-center gap-x-5 rounded-5 px-15 py-8 outline-none focus-visible:ring-2 focus-visible:ring-cipher;
}

.NavigationMenuTrigger::after {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 10px;
  content: '';
}

.NavigationMenuTrigger[data-state='open']::after {
  display: block;
}

.MobileNavTrigger,
.MobileNavLink {
  @apply block w-full px-20 py-15 text-left text-22 font-[440] leading-100 -tracking-1 outline-none focus-visible:bg-grayscale-01;
}

.MobileChevronDown {
  @apply mt-4 w-18;
}

.MobileNavTrigger {
  @apply flex items-center justify-between;

  &[data-state='open'] {
    @apply bg-grayscale-01;

    .MobileChevronDown {
      @apply -rotate-180;
    }
  }
}

.NavigationMenuLink {
  @apply block;
}

.NavigationMenuContent {
  @apply absolute left-0 top-0 w-full;
  animation-duration: 250ms;
  animation-timing-function: ease;
}

.NavigationMenuContent[data-motion='from-start'] {
  animation-name: enterFromLeft;
}

.NavigationMenuContent[data-motion='from-end'] {
  animation-name: enterFromRight;
}

.NavigationMenuContent[data-motion='to-start'] {
  animation-name: exitToLeft;
}

.NavigationMenuContent[data-motion='to-end'] {
  animation-name: exitToRight;
}

@media only screen and (min-width: 600px) {
  .NavigationMenuContent {
    width: auto;
  }
}

.NavigationMenuIndicator {
  display: flex;
  position: relative;
  top: 100%;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
  transition:
    width,
    transform 250ms ease;
  height: 8px;
  overflow: hidden;
  pointer-events: none;
}

.NavigationMenuIndicator[data-state='visible'] {
  animation: fadeIn 250ms ease;
}

.NavigationMenuIndicator[data-state='hidden'] {
  animation: fadeOut 250ms ease;
}

.NavigationMenuViewport {
  @apply rounded-10 border-1 border-grayscale-02 bg-cloud;
  position: relative;
  transform-origin: top center;
  transition:
    width,
    height,
    250ms ease;
  margin-top: 7px;
  width: 100%;
  height: var(--radix-navigation-menu-viewport-height);
  overflow: hidden;
}

.NavigationMenuViewport[data-state='open'] {
  animation: scaleIn 250ms ease;
}

.NavigationMenuViewport[data-state='closed'] {
  animation: scaleOut 250ms ease;
}

@media only screen and (min-width: 600px) {
  .NavigationMenuViewport {
    width: var(--radix-navigation-menu-viewport-width);
  }
}

.ViewportPosition {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  justify-content: center;
  perspective: 2000px;
  padding: 0 2.5rem;
  width: 100%;
}

.ChevronDown {
  @apply w-9 translate-y-2;
  transition: transform 250ms ease;
}

[data-state='open'] > .ChevronDown {
  @apply -rotate-180;
}

.Arrow {
  @apply z-2 border-1 border-grayscale-02 bg-cloud;
  position: relative;
  top: 70%;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
}

@keyframes enterFromRight {
  from {
    transform: translateX(200px);
    opacity: 0.001;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-200px);
    opacity: 0.001;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes exitToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(200px);
    opacity: 0.001;
  }
}

@keyframes exitToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-200px);
    opacity: 0.001;
  }
}

@keyframes scaleIn {
  from {
    transform: rotateX(-30deg) scale(0.9);
    opacity: 0.001;
  }

  to {
    transform: rotateX(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  from {
    transform: rotateX(0deg) scale(1);
    opacity: 1;
  }

  to {
    transform: rotateX(-10deg) scale(0.95);
    opacity: 0.001;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.001;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.001;
  }
}
